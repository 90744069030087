import { useCallback } from 'react';
import { OnChangeValue } from 'react-select';

import { Box } from 'components/Box/Box';
import { Select } from 'components/Select/Select';
import { OptionType } from 'components/Select/Select.types';
import { SearchFilterSection } from 'modules/search/components/FiltersSidebar/SearchFilterSection';
import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

type Props = {
  facet: SearchFacet;
  filtersById: SearchFiltersById;
  setSearchFilter: (facetId: SearchFacetId, value?: string) => void;
  expanded: boolean;
  toggleExpanded: () => void;
};

export function SearchFilterSectionRecencySelect({
  facet,
  filtersById,
  setSearchFilter,
  expanded,
  toggleExpanded,
}: Props) {
  const options = facet.options.map((option) => ({
    value: option,
    label: facet.optionTitles[option],
  }));

  const handleChange = useCallback(
    (newValue: OnChangeValue<OptionType, false>) => {
      setSearchFilter(
        'recencyFacet',
        newValue?.value !== 'ANYTIME' ? newValue?.value : undefined,
      );
    },
    [setSearchFilter],
  );

  return (
    <SearchFilterSection
      title={facet.title}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      data-qa-id="side-filter-facet-recency"
      data-qa-filter-value={
        (filtersById[facet.id] as string | undefined) || 'ANYTIME'
      }
      count={filtersById[facet.id] ? 1 : 0}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Box mt="24px" zIndex={2}>
          <Select
            name="recency"
            value={(filtersById[facet.id] as string | undefined) || 'ANYTIME'}
            options={options}
            onChange={handleChange}
            menuPosition="fixed"
            isSearchable={false}
            data-qa-id="search-recency-selector"
          />
        </Box>
      </div>
    </SearchFilterSection>
  );
}
