import { ComponentType, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { Icon } from 'components/Icon/Icon';
import { LegacyButton } from 'components/LegacyButton/LegacyButton';
import { colors, mediaRetina, transition } from 'theme/theme';
import { isDefined } from 'utils/functional/type';

import { PillText } from './Pill.styled';

type Props = {
  title?: string;
  text: string;
  isLoading?: boolean;
  onClick: MouseEventHandler;
  active?: boolean;
  'aria-label'?: string;
  component?: ComponentType<{
    borderRadius: number | string;
    p: string;
    height: string | number;
    'aria-label': string;
    title: string;
    onClick: MouseEventHandler;
  }>;
};

export const Pill = styled(
  ({
    title,
    text,
    isLoading,
    onClick,
    'aria-label': ariaLabel,
    active,
    component,
    ...props
  }: Props) => {
    const Component = component || LegacyButton;
    return (
      <Component
        borderRadius={20}
        p="2px 12px"
        height={32}
        aria-label={ariaLabel || title}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        title={title}
        onClick={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <PillText style={{ filter: isLoading ? 'blur(5px)' : undefined }}>
            {isLoading && !text ? '----' : text}
          </PillText>

          {(!isDefined(active) || active) && (
            <Box flex="0 0 auto" ml="8px">
              <Icon name="close" size={8} />
            </Box>
          )}
        </Box>
      </Component>
    );
  },
)<{ $outline?: boolean }>`
  max-width: 100%;
  display: flex;
  align-items: center;
  ${(props) => `
    background: ${
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-nested-ternary
      props.active
        ? colors.brandBlue
        : props.$outline
          ? 'transparent'
          : colors.calloutGrey
    };
    color: ${
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-nested-ternary
      props.active
        ? 'white'
        : props.$outline
          ? colors.lightContentGrey
          : colors.brandBlue
    };
    ${
      props.$outline && !props.active
        ? `
      box-shadow: 0 0 0 1px ${colors.selectionGrey} inset;
      &:hover {
        box-shadow: 0 0 0 1px transparent inset;
      }
      ${mediaRetina} {
        box-shadow: 0 0 0 1px ${colors.selectionGrey} inset;
        &:hover {
          box-shadow: 0 0 0 1px transparent inset;
        }
      }
    `
        : ''
    }
    &:hover {
      background: ${props.active ? colors.hoverBlue : colors.brandBlue};
    }
  `}
  transition: all ${transition};

  &:hover {
    color: white;
  }
`;
