import { format, parseISO } from 'date-fns';
import deepEqual from 'react-fast-compare';

import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { sort as sortFn } from 'utils/functional/array';

import { SearchFiltersSidebarFilterPill } from './SearchFiltersSidebarFilterPill';

type Args = {
  facet: SearchFacet;
  filtersById: SearchFiltersById;
  selectAllSearchFilterOptions: (facetId: SearchFacetId) => void;
  clearFilter: (facetId: SearchFacetId, option: string) => void;
  setFilter: (
    facetId: SearchFacetId,
    value:
      | string
      | { endsGT: string | undefined; startsLT: string | undefined }
      | null
      | undefined,
  ) => void;
  searchLocation: SearchLocation | null | undefined;
  searchLocationActive: boolean;
  jobSynonym: { id: string; name: string } | undefined;
};

export function searchFiltersSidebarMapFacetToPill({
  facet,
  filtersById,
  selectAllSearchFilterOptions,
  clearFilter,
  setFilter,
  jobSynonym,
}: Args) {
  const filter = filtersById[facet.id];

  if (
    Array.isArray(filter) &&
    (facet.id === 'jobFamilyFacet' || filter.length < facet.options.length)
  ) {
    return filter.map((option) => (
      <SearchFiltersSidebarFilterPill
        key={option}
        text={
          facet.id === 'jobFamilyFacet'
            ? `Similar to: ${jobSynonym?.name}`
            : facet.optionTitles[option]
        }
        clearFilter={() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const allOptionsSelected = ({ searchFilter, searchFacet }: any) =>
            (searchFilter &&
              Array.isArray(searchFilter) &&
              deepEqual(sortFn(searchFilter), sortFn(searchFacet.options))) ||
            !searchFilter ||
            searchFilter === 'ALL';

          const isSelected =
            filter &&
            filter.includes(option) &&
            !allOptionsSelected({
              searchFilter: filter,
              searchFacet: facet,
            });

          return isSelected && deepEqual([option], filter)
            ? selectAllSearchFilterOptions(facet.id)
            : clearFilter(facet.id, option);
        }}
      />
    ));
  }

  if (facet.id === 'actionDateFacet') {
    const actionDateValue = (filter || {}) as {
      endsGT: string | undefined;
      startsLT: string | undefined;
    };

    return Object.entries(actionDateValue)
      .filter(([_key, value]) => value)
      .map(([key, value]) => (
        <SearchFiltersSidebarFilterPill
          key={key}
          text={`${
            key === 'endsGT' ? getText('After') : getText('Before')
          }: ${format(parseISO(value as string), 'MM/dd/yyyy')}`}
          clearFilter={() =>
            setFilter(facet.id, { ...actionDateValue, [key]: undefined })
          }
        />
      ));
  }

  if (facet.id === 'recencyFacet' && filter) {
    return [
      <SearchFiltersSidebarFilterPill
        key={facet.id}
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error fix types
        text={facet.optionTitles[filter]}
        clearFilter={() => setFilter(facet.id, null)}
      />,
    ];
  }

  return [];
}
