import { create } from 'zustand';

import { configString } from 'config/appConfigUtils';
import { onClientHydrated } from 'rendering/state/renderingState';

const loadCssBundleStore = create<{
  bundles: Record<string, true>;
}>(() => ({ bundles: {} }));

const useLoadCssBundleStore = loadCssBundleStore; // The store can be used as a hook

const bundlesLoading: string[] = [];

// Actions

function setCssBundleLoaded(name: string) {
  const { bundles } = loadCssBundleStore.getState();
  loadCssBundleStore.setState({ bundles: { ...bundles, [name]: true } });
}

function loadCssBundle(name: string) {
  const styleEl = document.createElement('link');
  styleEl.href = `${configString('idealist', 'staticJsPath')}../${name}.css`;
  styleEl.type = 'text/css';
  styleEl.rel = 'stylesheet';
  styleEl.onload = () => setCssBundleLoaded(name);

  document.head.appendChild(styleEl);
}

// Hooks

export function useLoadBundleCssOnce(name: string | undefined) {
  const cssLoaded = useLoadCssBundleStore((state) =>
    Boolean(name && state.bundles[name]),
  );

  if (!name) return { cssLoaded: false };

  if (!cssLoaded && !bundlesLoading.includes(name)) {
    bundlesLoading.push(name);
    onClientHydrated(() => loadCssBundle(name));
  }

  return { cssLoaded };
}
