import { format, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { Box } from 'components/Box/Box';
import { DatePicker } from 'components/Date/DatePicker';
import { Label } from 'components/Label/Label';
import { SearchFilterSection } from 'modules/search/components/FiltersSidebar/SearchFilterSection';
import { actionDateFacet } from 'modules/search/constants/searchFacets/actionDateFacet';
import { SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { objectEmpty } from 'utils/functional/object';

type Props = {
  facet: typeof actionDateFacet;
  filtersById: SearchFiltersById;
  setSearchFilter: (
    facetId: SearchFacetId,
    value?: string | Record<string, unknown> | null,
  ) => void;
  expanded: boolean;
  toggleExpanded: () => void;
};

export function SearchFiltersSidebarDateFacet({
  facet,
  filtersById,
  setSearchFilter,
  expanded,
  toggleExpanded,
}: Props) {
  // Access the search filter's values
  const [filterStart, filterEnd] = useMemo(() => {
    // TODO: Fix this the next time the file is edited.
    // @ts-expect-error fix types
    const filter: Record<string, string | null> = filtersById[facet.id]; // { endsGT: '2024-12-24', startsLT: '2024-12-24' }

    const startValue =
      !objectEmpty(filter) && typeof filter === 'object' ? filter.endsGT : null;
    const endValue =
      !objectEmpty(filter) && typeof filter === 'object'
        ? filter.startsLT
        : null;

    return [startValue, endValue];
  }, [filtersById, facet]);

  const onChangeStart = useCallback(
    (dateString?: string | null) => {
      const date = dateString ? parseISO(dateString) : null;
      const formatted = date ? format(date, 'yyyy-MM-dd') : null;

      setSearchFilter('actionDateFacet', {
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error fix types
        ...filtersById.actionDateFacet,
        endsGT: formatted,
      });
    },
    [setSearchFilter, filtersById],
  );

  const onChangeEnd = useCallback(
    (dateString: string | null) => {
      const date = dateString ? parseISO(dateString) : null;
      const formatted = date ? format(date, 'yyyy-MM-dd') : null;

      setSearchFilter('actionDateFacet', {
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error fix types
        ...filtersById.actionDateFacet,
        startsLT: formatted,
      });
    },
    [setSearchFilter, filtersById],
  );

  return (
    <SearchFilterSection
      title={facet.title}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      data-qa-id={`side-filter-facet-${facet.name}`}
      data-qa-filter-value={[filterStart, filterEnd].join()}
      count={[filterStart, filterEnd].filter((a) => Boolean(a)).length}
      noMask
    >
      <Box display="flex" flexDirection="column" mt="14px">
        <Box mt="10px">
          <Label data-qa-id="endsGT-label">{getText('Start Date')}</Label>
          <DatePicker
            value={filterStart}
            onChange={onChangeStart}
            placeholder={getText('Select')}
            name="endsGT"
            disablePastDates
            isTimeZoneAware
          />
        </Box>
        <Box mt="10px">
          <Label>{getText('End Date')}</Label>
          <DatePicker
            value={filterEnd}
            onChange={onChangeEnd}
            placeholder={getText('Select')}
            name="startsLT"
            disablePastDates
            isTimeZoneAware
          />
        </Box>
      </Box>
    </SearchFilterSection>
  );
}
