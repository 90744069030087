import { ReactNode } from 'react';

import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

import { SearchFilterSectionArrayFacet } from './ArrayOrBoolean/SearchFilterSectionArrayFacet';
import { SearchFiltersSidebarDateFacet } from './Date/SearchFiltersSidebarDateFacet';
import { SearchFiltersSidebarRecencyFacet } from './Recency/SearchFiltersSidebarRecencyFacet';

type Props = {
  facet: SearchFacet;
  filtersById: SearchFiltersById;
  keywords: string[];
  setSearchFilter: (
    facetId: SearchFacetId,
    value?: string | Record<string, unknown> | null,
  ) => void;
  toggleCurrentSection: (index: null | string) => void;
  currentSection: string | null;
  header?: ReactNode;
  selectSearchFilterOption: (facetId: SearchFacetId, option: string) => void;
  deselectSearchFilterOption: (facetId: SearchFacetId, option: string) => void;
  selectAllSearchFilterOptions: (facetId: SearchFacetId) => void;
  searchableFacets: string[];
  footer?: ReactNode;
};

export function SearchFiltersSidebarFacetSwitch({
  facet,
  filtersById,
  keywords,
  setSearchFilter,
  toggleCurrentSection,
  currentSection,
  selectSearchFilterOption,
  deselectSearchFilterOption,
  header,
  selectAllSearchFilterOptions,
  searchableFacets,
  footer,
}: Props) {
  switch (facet.type) {
    case 'date':
      return (
        <SearchFiltersSidebarDateFacet
          facet={facet}
          filtersById={filtersById}
          setSearchFilter={setSearchFilter}
          toggleExpanded={() => toggleCurrentSection(facet.name)}
          expanded={currentSection === facet.name}
        />
      );
    case 'string':
      if (facet.name !== 'recency') return null;

      return (
        <SearchFiltersSidebarRecencyFacet
          facet={facet}
          filtersById={filtersById}
          setSearchFilter={setSearchFilter}
          currentSection={currentSection}
          toggleCurrentSection={toggleCurrentSection}
        />
      );

    case 'array':
      return (
        <SearchFilterSectionArrayFacet
          facet={facet}
          filtersById={filtersById}
          keywords={keywords}
          setSearchFilter={setSearchFilter}
          toggleCurrentSection={toggleCurrentSection}
          currentSection={currentSection}
          selectSearchFilterOption={selectSearchFilterOption}
          deselectSearchFilterOption={deselectSearchFilterOption}
          selectAllSearchFilterOptions={selectAllSearchFilterOptions}
          searchableFacets={searchableFacets}
          header={header}
          footer={footer}
        />
      );
  }
}
