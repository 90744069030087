import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { cssBreakpoints } from 'theme/theme';

export const SearchFilterSectionFiltersBox = styled(Box)<{ $noMask?: boolean }>`
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    padding: 0 20px 17px;
    margin: 0 -20px -17px;
    margin-top: 1px;
    ${(props) =>
      !props.$noMask &&
      `
    overflow-x: hidden;
    overflow-y: auto;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 20px;
    }
    &::before {
      top: 0;
      background: linear-gradient(to bottom,
        rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
    }
    &::after {
      bottom: 0;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0)
      );
    }`}
  }
`;
